
import { mapGetters } from 'vuex'

import { mixin as clickaway } from 'vue-clickaway'

// import Condition from '@/components/Condition'
import Rating from '@/components/store/partials/Rating'
import Images from '@/components/store/partials/Images'

export default {
  components: {
    // Condition,
    Rating,
    Images
  },

  mixins: [
    clickaway
  ],

  props: {
    product: {
      type: Object,
      default () {
        return {}
      },
      required: true
    },
    store: {
      type: Object,
      default () {
        return {}
      },
      required: true
    }
  },

  data: () => ({
    delivery: {
      active: true
    },
    performance: {
      tests: {
        active: false
      }
    },
    warranty: {
      types: [
        //
      ]
    },
    addon: {
      types: [
        //
      ]
    }
  }),

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      country: 'country',
      currency: 'currency',
      localDeliveryOptions: 'localDeliveryOptions',
      location: 'location'
    }),

    conditionRating () {
      if (this.store.conditions.length > 0) {
        return Math.round(this.store.conditions.reduce((total, next) => total + next.option.stars, 0) / this.store.conditions.length * 10) / 10
      } else {
        return this.store.condition
      }
    },

    addOnPrice () {
      let addonsPrice = 0
      let warrantiesPrice = 0

      this.addon.types.forEach((type) => {
        addonsPrice += (Object.keys(type.selected).length ? type.selected.price : 0)
      })

      this.warranty.types.forEach((type) => {
        warrantiesPrice += (Object.keys(type.selected).length ? type.selected.price : 0)
      })

      return warrantiesPrice + addonsPrice
    },

    oldPrice () {
      if (this.store.price.old) {
        return this.store.price.old + this.addOnPrice
      }

      return 0
    },

    price () {
      return this.store.price.current + this.addOnPrice
    },

    discountPercent () {
      return this.oldPrice ? Math.ceil((this.oldPrice - this.price) / this.oldPrice * 100) : 0
    },

    discount () {
      return this.oldPrice ? this.oldPrice - this.price : 0
    }
  },

  created () {
    this.store.warranty_types.forEach((type) => {
      const warrantyTypeIndex = this.warranty.types.push({
        id: type.id,
        plural: type.plural,
        selected: {},
        isActive: false,
        tags: {
          isActive: false
        },
        warranties: JSON.parse(JSON.stringify(type.warranties))
      })

      const warrantyType = this.warranty.types[warrantyTypeIndex - 1]
      const defaultWarranty = warrantyType.warranties.find(warranty => warranty.default === true)

      if (defaultWarranty) {
        this.changeWarranty(defaultWarranty, warrantyType.id)
      }
    })

    this.store.addon_types.forEach((type) => {
      const addonTypeIndex = this.addon.types.push({
        id: type.id,
        plural: type.plural,
        selected: {},
        isActive: false,
        tags: {
          isActive: false
        },
        addons: JSON.parse(JSON.stringify(type.addons))
      })

      const addonType = this.addon.types[addonTypeIndex - 1]
      const defaultAddon = addonType.addons.find(addon => addon.default === true)

      if (defaultAddon) {
        this.changeAddon(defaultAddon, addonType.id)
      }
    })
  },

  methods: {
    openWarrantyTypeInfoModal (warrantyType) {
      this.$store.dispatch('modal/setInfoModalTitle', warrantyType.title)
      this.$store.dispatch('modal/setInfoModalDescription', warrantyType.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    toggleWarrantyTypeDropdown (warrantyTypeId) {
      if (this.addon.types.length) {
        this.hideAddonDropDown()
      }

      const otherWarranties = this.warranty.types.find(type => type.id !== warrantyTypeId)

      if (otherWarranties) {
        otherWarranties.isActive = false
      }

      this.warranty.types.find(type => type.id === warrantyTypeId).isActive = !this.warranty.types.find(type => type.id === warrantyTypeId).isActive
    },

    hideWarrantyDropDown () {
      this.warranty.types.forEach((type) => {
        type.isActive = false
      })
    },

    changeWarranty (warranty, warrantyTypeId) {
      this.hideWarrantyDropDown()

      this.warranty.types.find(type => type.id === warrantyTypeId).selected = warranty

      if (warranty === false) {
        this.warranty.types.find(type => type.id === warrantyTypeId).warranties.shift()
      }

      if (this.warranty.types.find(type => type.id === warrantyTypeId).warranties[0] && warranty) {
        this.warranty.types.find(type => type.id === warrantyTypeId).warranties.unshift(false)
      }

      this.$emit('warrantySelected', this.warranty.types.filter(c => c.selected && Object.keys(c.selected).length !== 0 && c.selected.constructor === Object).map(c => c.selected))
    },

    toggleAddonTypeDropdown (addonTypeId) {
      if (this.warranty.types.length) {
        this.hideWarrantyDropDown()
      }

      const otherAddons = this.addon.types.find(type => type.id !== addonTypeId)

      if (otherAddons) {
        otherAddons.isActive = false
      }

      this.addon.types.find(type => type.id === addonTypeId).isActive = !this.addon.types.find(type => type.id === addonTypeId).isActive
    },

    hideAddonDropDown () {
      this.addon.types.forEach((type) => {
        type.isActive = false
      })
    },

    changeAddon (addon, addonTypeId) {
      this.hideAddonDropDown()

      this.addon.types.find(type => type.id === addonTypeId).selected = addon

      if (addon === false) {
        this.addon.types.find(type => type.id === addonTypeId).addons.shift()
      }

      if (this.addon.types.find(type => type.id === addonTypeId).addons[0] && addon) {
        this.addon.types.find(type => type.id === addonTypeId).addons.unshift(false)
      }

      this.$emit('addonSelected', this.addon.types.filter(c => c.selected && Object.keys(c.selected).length !== 0 && c.selected.constructor === Object).map(c => c.selected))
    },

    openPerformanceTestsModal () {
      this.performance.tests.active = true
    },

    closePerformanceTestsModal () {
      this.performance.tests.active = false
    },

    storeShipmentTimeOfDeliveryMethod (deliveryMethod) {
      // Add 1 day if the current time is more than the shipment_before time
      const shipTodayAddDays = this.$moment().locale(this.$i18n.locale).format('HH:mm:ss') < deliveryMethod.shipment_before ? 0 : 1

      const from = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.store.shipment_min, `${this.store.shipment_min_unit.name}s`)
        .add(this.store.stockStatus.shipment_time_min, `${this.store.stockStatus.shipment_time_min_unit.name}s`)
        .add(deliveryMethod.delivery_time_min, `${deliveryMethod.delivery_time_min_unit.name}s`)

      const to = this.$moment().locale(this.$i18n.locale)
        .add(shipTodayAddDays, 'days')
        .add(this.store.shipment_max, `${this.store.shipment_max_unit.name}s`)
        .add(this.store.stockStatus.shipment_time_max, `${this.store.stockStatus.shipment_time_max_unit.name}s`)
        .add(deliveryMethod.delivery_time_max, `${deliveryMethod.delivery_time_max_unit.name}s`)

      if (to.year() === from.year() && to.month() === from.month() && to.date() === from.date()) {
        return to.format('ll')
      } else if (to.year() === from.year() && to.month() === from.month() && to.date() !== from.date()) {
        return `${from.format('DD')} — ${to.format('ll')}`
      } else if (to.year() === from.year() && to.month() !== from.month() && to.date() !== from.date()) {
        return `${from.format('DD MMM')} — ${to.format('ll')}`
      } else {
        return `${from.format('ll')} — ${to.format('ll')}`
      }
    },

    addToCart (item, itemStore, warranties, addons) {
      this.$store.dispatch('cart/setCartType', 'cart')
      this.$store.dispatch('cart/addToCart', { item, itemStore, warranties, addons })
      this.$store.dispatch('cart/setCartModalActive', !this.cartModal.active)
    },

    togglePreorderModal () {
      this.$store.dispatch('modal/setPreorderId', this.product.id)
      this.$store.dispatch('modal/setPreorderTitle', this.product.title)
      this.$store.dispatch('modal/setPreorderImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/togglePreorderModal', true)
    },

    toggleNotifyAboutAvailabilityModal () {
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityId', this.product.id)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityTitle', this.product.title)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityImage', {
        src: `${this.product.images[0].folder}/card/src/${this.product.images[0].url}`,
        lazy: `${this.product.images[0].folder}/card/lazy/${this.product.images[0].url}`,
        alt: this.product.images[0].alt
      })
      this.$store.dispatch('modal/toggleNotifyAboutProductAvailabilityModal', true)
    },

    openConditionStatusInfoModal (conditionStatus) {
      this.$store.dispatch('modal/setInfoModalTitle', conditionStatus.title)
      this.$store.dispatch('modal/setInfoModalDescription', conditionStatus.description)
      this.$store.dispatch('modal/setInfoModalActive', true)
    },

    goToReviews () {
      this.$router.push(
        this.localePath({
          name: 'slug',
          params: {
            slug: this.store.page.slug
          },
          query: {
            tab: 'reviews'
          }
        })
      )
    }
  }
}
